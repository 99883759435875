<template>
  <div class="flexbox">
    <div class="bg">
      <img src="../assets/bg2.jpg" />
    </div>
    <transition>
      <div
        class="main"
        :class="{
          animation: !animation && animationON,
          reanimation: animation && animationON,
        }"
      >
        <div class="header">
          <div class="header_title">
            {{
              this.$route.query.demo === "true"
                ? this.$route.query.siteName
                : sliderInfo.site === "海創一期"
                ? "海創中心"
                : sliderInfo.site === "海創二期"
                ? "海創人培中心"
                : sliderInfo.site
            }}
            <div class="header_text_wrap d-flex align-items-center">
              <div class="text-nowrap">
                設置容量<br /><span class="text_translate">Capacity</span>
              </div>
              <div>：</div>
              <div class="text-nowrap">
                <span class="header_text_num">{{
                  dataHandle(sliderInfo.capacity)
                }}</span>
                kWp
              </div>
            </div>
          </div>
        </div>

        <div class="connectStyle">
          <span>{{ sliderInfo.msg }}</span>
        </div>

        <div class="info">
          <div class="left">
            <div class="title">
              <div class="text-center">
                <div class="text_title">案場畫面</div>
                <div class="text_translate">Photo(s)</div>
              </div>
            </div>
            <div class="imgbox" v-if="imglist">
              <Slider
                animation="fade"
                v-model="sliderValue"
                height="100%"
                :duration="1500"
                :speed="5000"
                :control-btn="this.imglist.length === 1 ? false : true"
                :indicators="this.imglist.length === 1 ? false : 'center'"
              >
                <SliderItem
                  v-for="(i, index) in imglist"
                  :key="index"
                  @click="changeIndex(1)"
                >
                  <img :src="i.imgsrc" style="height: 100%; width: 100%" />
                </SliderItem>
              </Slider>
            </div>
            <div class="imgbox center" v-else-if="photoload">
              <h3>尚未上傳照片，請聯絡管理人員</h3>
            </div>
          </div>
          <div class="right">
            <div class="infobox">
              <div class="title">
                <i class="fas fa-bolt" style="color: yellow"></i>
                <span> 今日發電量</span>
                <div class="text_translate">Power Generation Today</div>
              </div>
              <div class="text">
                {{ dataHandle(sliderInfo.eToday) }}
                <span class="unit"><p>kWh</p></span>
              </div>
            </div>

            <div class="infobox">
              <div class="title">
                <i class="fas fa-bolt" style="color: yellow"></i>
                <span> 發電總量</span>
                <div class="text_translate">Total Power Generation</div>
              </div>
              <div class="text">
                {{ dataHandle(sliderInfo.acKwh) }}
                <span class="unit"><p>kWh</p></span>
              </div>
            </div>

            <div class="infobox">
              <div class="title">
                <i class="fas fa-bolt" style="color: yellow"></i>
                <span> 本月發電量</span>
                <div class="text_translate">Power Generation this Month</div>
              </div>
              <div class="text">
                {{ dataHandle(sliderInfo.acKwhThisMon) }}
                <span class="unit"><p>kWh</p></span>
              </div>
            </div>

            <div class="infobox">
              <div class="title">
                <i class="fas fa-signal" style="color: yellow"></i>
                <span> 峰瓩值</span>
                <div class="text_translate">Peak Value</div>
              </div>
              <div class="text">
                {{ dataHandle(sliderInfo.peakWatt) }}
                <span class="unit"><p>kWh/kWp</p></span>
              </div>
            </div>

            <div class="infobox">
              <div class="title">
                <i class="fas fa-chart-line" style="color: yellow"></i>
                <span> PR 值</span>
                <div class="text_translate">Performance Ratio</div>
              </div>
              <div class="text">
                {{ dataHandle(sliderInfo.PR * 100, 1) }}
                <span class="unit"><p>%</p></span>
              </div>
            </div>

            <div class="infobox">
              <div class="title">
                <i class="fas fa-sun" style="color: yellow"></i>

                <span> 日照強度</span>
                <div class="text_translate">Intensity of Sunlight</div>
              </div>
              <div class="text">
                {{
                  handleIrr(sliderInfo.IRR)
                    ? dataHandle(sliderInfo.IRR * 1000)
                    : "null"
                }}
                <span class="unit"><p>W/m²</p></span>
              </div>
            </div>
            <div class="infobox">
              <div class="title">
                <i class="fas fa-grip-lines" style="color: yellow"></i>
                <span> 直流功率</span>
                <div class="text_translate">&emsp;&nbsp; DC Power</div>
              </div>
              <div class="text">
                {{ dataHandle(sliderInfo.dcp) }}
                <span class="unit"><p>kW</p></span>
              </div>
            </div>
            <div class="infobox">
              <div class="title">
                <i class="fas fa-bacon" style="color: yellow"></i>
                <span> 交流功率</span>
                <div class="text_translate">&emsp;&nbsp; AC Power</div>
              </div>
              <div class="text">
                {{ dataHandle(sliderInfo.acp) }}
                <span class="unit"><p>kW</p></span>
              </div>
            </div>
            <div class="infobox">
              <div
                class="title d-flex flex-column align-items-center justify-content-center"
                :style="{ height: moduleTempTitleHeight + 'px' }"
              >
                <div>
                  <i class="fas fa-thermometer-half" style="color: red"></i>
                  <span> 模組溫度</span>
                </div>
                <div class="text_translate">Module Temperature</div>
              </div>
              <div class="text">
                {{ dataHandle(sliderInfo.moduleTemp) }}
                <span class="unit"><p>°C</p></span>
              </div>
            </div>
            <div class="infobox">
              <div class="title" ref="refCo2Title">
                <i class="fas fa-leaf" style="color: green"></i>
                <span> 累積 CO2 減排量</span>
                <div class="text_translate">
                  Cumulative CO2 Emissions Reduction
                </div>
              </div>
              <div class="text">
                {{ dataHandle(sliderInfo.CO2) }}
                <span class="unit"><p>ton</p></span>
              </div>
            </div>
            <div class="update">
              最後更新時間 Last Updated :
              <span style="color: #ff0">&nbsp;{{ sliderInfo.time }}</span>
            </div>
          </div>
        </div>
        <div class="bottom">
          <div class="bottomText">
            太陽能系統啟動時間
            <span class="text_translate">Solar System Startup Time</span>：{{
              sliderInfo.systemTime
            }}
          </div>
        </div>
      </div>
    </transition>
    <!-- //slider 好了再打開 -->
    <i
      v-if="!singleFactory"
      @click="sliderCount('minus')"
      class="leftButton fas fa-chevron-circle-left"
      style="font-size: 45px; cursor: pointer"
    ></i>
    <i
      v-if="!singleFactory"
      @click="sliderCount('plus')"
      class="rightButton fas fa-chevron-circle-right"
      style="font-size: 45px; cursor: pointer"
    ></i>
    <a v-if="!singleFactory" class="lock" @click="lockSlider">{{ lockText }}</a>
  </div>
</template>

<script>
import { getLinkGroupApi, getLinkDataApi, getLinkBlobApi } from '../api'
import { mapState, mapActions, mapMutations } from 'vuex'
import { DateTime } from 'luxon'
import { dbDataHandle } from '../store/function/common/index'
export default {
  name: 'TvScreen',
  data () {
    return {
      animationON: false,
      photoload: false,
      singleFactory: true,
      lockText: '案場鎖定',
      lock: false,
      animation: false,
      imglist: [],
      sliderValue: 0,
      linkData: {},
      factoryName: '',
      time: '',
      systemTime: '',
      finalObject: {},
      timer: null,
      reload: null,
      infoArray: [],
      sliderInfo: {},
      sliderInfoCount: 0,
      slider: null,
      groupDataArray: [],
      linkPhotoArray: [],
      moduleTempTitleHeight: 80
    }
  },
  computed: {
    ...mapState(['factoryInfo', 'querySiteName'])
  },
  methods: {
    ...mapActions(['asyncInfo']),
    ...mapMutations(['ADD_QUERY_SITE_NAME']),
    handleIrr (value) {
      if (value === undefined || value.length === 0 || value === null) {
        return false
      } else {
        return true
      }
    },
    changeIndex (index) {
      this.sliderValue = index
    },
    sliderCount (string) {
      this.animationON = true
      if (string === 'plus') {
        this.sliderInfoCount = this.sliderInfoCount + 1
        this.animation = !this.animation
      }
      if (string === 'minus') {
        if (this.sliderInfoCount > 0) {
          this.sliderInfoCount = this.sliderInfoCount - 1
          this.animation = !this.animation
        }
      }
      setTimeout(() => {
        this.infoChange()
      }, 700)
    },
    lockSlider () {
      this.lock = !this.lock
      this.lockText = this.lock ? '啟動輪播' : '案場鎖定'
    },
    async getLinkGroup () {
      if (this.$route.query.groupNumber === '0') {
        this.singleFactory = true
        this.lock = true
        this.groupDataArray[0] = []
        this.groupDataArray[0].factoryId = this.$route.query.fid
        this.groupDataArray[0].factoryName = this.querySiteName
        this.groupDataArray[0].sysTimeStartFrom = this.$route.query.systemTime
      } else {
        const groupData = await getLinkGroupApi(this.$route.query.groupNumber)
        if (groupData?.data?.success) {
          this.groupDataArray = groupData.data.data.sort(
            (a, b) => a.factoryId - b.factoryId
          )
          this.singleFactory = false
        }
      }
    },
    async getLink () {
      const infoArray = []
      for (let i = 0; i < this.groupDataArray.length; i++) {
        // slider 好了再打開
        const id = this.groupDataArray[i].factoryId
        const factoryName = this.groupDataArray[i].factoryName
        const systemTime = this.groupDataArray[i].sysTimeStartFrom
        const linkData = await getLinkDataApi(
          factoryName,
          id,
          DateTime.local().setZone('Asia/Taipei').toFormat('yyyy-MM')
        )
        if (linkData?.data?.success) {
          this.finalObject = linkData.data.data
          this.finalObject.concent = true
        } else {
          this.finalObject = {}
          this.finalObject.concent = false
        }
        this.finalObject.msg = linkData.data.msg
        this.finalObject.site = factoryName
        this.finalObject.time = DateTime.local()
          .setZone('Asia/Taipei')
          .toFormat('yyyy-MM-dd HH:mm:ss')
        this.finalObject.systemTime = systemTime
        infoArray.push(this.finalObject)
      }
      this.infoArray = JSON.parse(JSON.stringify(infoArray))
      this.infoChange()
    },
    async getPhoto () {
      this.linkPhotoArray = []
      for (let j = 0; j < this.groupDataArray.length; j++) {
        const isDemoName = this.$route.query.siteName.includes('展示案場')
        const querySiteFid = isDemoName
          ? 'f000'
          : this.groupDataArray[j].factoryId

        const linkPhoto = await getLinkBlobApi(querySiteFid)
        if (linkPhoto.data.data.length > 0) {
          this.linkPhotoArray[j] = []
          for (let i = 0; i < linkPhoto.data.data.length; i++) {
            let a = {
              imgsrc: linkPhoto.data.data[i]
            }
            this.linkPhotoArray[j].push(a)
          }
        }
      }
      if (this.linkPhotoArray.every((arr) => !!arr.length)) {
        this.photoload = true
      }
    },
    infoChange () {
      const infoLen = this.infoArray.length
      if (this.sliderInfoCount >= infoLen) {
        this.sliderInfoCount = 0
      }
      this.sliderInfo = this.infoArray[this.sliderInfoCount]
      this.imglist = this.linkPhotoArray[this.sliderInfoCount]
    },
    dataHandle (val, index) {
      return dbDataHandle(val, index)
    },
    tvInterval () {
      this.timer = setInterval(() => {
        this.getLink()
      }, 60000)
      this.slider = setInterval(() => {
        if (!this.lock) {
          this.sliderInfoCount = this.sliderInfoCount + 1
          this.animationON = true
          this.animation = !this.animation
          setTimeout(() => {
            this.infoChange()
          }, 700)
        }
      }, 20000)
    },
    clearTvInterval () {
      clearInterval(this.timer)
      clearInterval(this.slider)
      this.timer = null
      this.slider = null
    },
    handleResize () {
      this.$nextTick(() => {
        this.moduleTempTitleHeight =
          this.$refs.refCo2Title.getBoundingClientRect().height
      })
    }
  },
  async mounted () {
    this.moduleTempTitleHeight =
      this.$refs.refCo2Title.getBoundingClientRect().height
    window.addEventListener('resize', this.handleResize)

    if (!this.factoryInfo.length) await this.asyncInfo()
    this.ADD_QUERY_SITE_NAME(this.$route.query.siteName)
    await this.getLinkGroup()
    await this.getLink()
    await this.getPhoto()
    this.infoChange()
    this.tvInterval()
    this.reload = setInterval(() => {
      this.clearTvInterval()
      this.tvInterval()
    }, 3600000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
    clearInterval(this.reload)
    clearInterval(this.slider)
    this.slider = null
    this.timer = null
    this.reload = null

    window.removeEventListener('resize', this.handleResize)
  }
}
</script>

<style lang="scss" scoped>
@keyframes marque-animation {
  0% {
    transform: translateX(70%);
  }
  100% {
    transform: translateX(-75%);
  }
}
@keyframes slider-animation {
  0% {
  }
  50% {
    opacity: 0;
  }
  100% {
  }
} //slider 好了再打開
@keyframes reslider-animation {
  0% {
  }
  50% {
    opacity: 0;
  }
  100% {
  }
}
#app .sidebar-view-wrapper .router-view {
  background: none;
  overflow-x: hidden;
}
.connectStyle {
  font-size: 1vw;
  font-weight: 700;
  text-align: end;
  margin-right: 0.8%;
  margin-top: 0.4%;
  margin-bottom: -0.4%;
}
.center {
  display: flex;
  justify-content: center;
  align-items: center;
}
.flexbox {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 98vh;
  min-height: 860px;
  position: relative;
  .rightButton {
    position: absolute;
    right: 15px;
  }
  .leftButton {
    position: absolute;
    left: 15px;
  }
  .lock {
    position: absolute;
    bottom: 3px;
    line-height: 12px;
    padding: 8px 12px;
    font-size: 17px;
    font-weight: 400;
    color: #fff;
    border-radius: 18px;
    background: #018e9c;
    text-decoration: none;
    transition: 0.2s;
    cursor: pointer;
  }
  .animation {
    animation: slider-animation 1.3s linear;
  }
  .reanimation {
    animation: reslider-animation 1.3s linear;
  }
  .main {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-align: center;
    width: 90%;
    background-color: rgba(255, 255, 255, 0.6);
    box-shadow: 1px 1px #aaa;
    border: 1px solid #fff;
    border-radius: 10px;
    .header {
      background: linear-gradient(to bottom, #fff 0, #e5e5e5 100%);
      height: 85px;
      border-radius: 10px 10px 0 0;
      box-shadow: 0 1px #fff;
      border-bottom: 1px solid #aaa;
      &_title {
        position: relative;
        text-shadow: 1px 1px #aaa;
        color: #3d90e9;
        font-weight: 700;
        font-size: 2.5vw;
        line-height: 85px;
      }
      &_text_wrap {
        position: absolute;
        font-size: 1.3vw;
        line-height: 1;
        right: 2vw;
        top: 50%;
        transform: translateY(-50%);
      }

      &_text_num {
        font-size: 1.4vw;
      }

      .text_translate {
        font-size: 1.15vw;
      }
    }

    .text_translate {
      font-size: 1.02vw;
      // letter-spacing: -0.2px;
    }

    .info {
      display: flex;
      .left {
        width: 60%;
        border: 1px solid #fff;
        box-shadow: 1px 1px #777;
        border-radius: 7px;
        margin: 0.8%;
        margin-right: 0.4%;
        min-height: 670px;
        .imgbox {
          padding: 10px;
          height: 600px;
        }
      }
      .right {
        display: flex;
        flex-wrap: wrap;
        margin: 0.4%;
        margin-left: 0;
        width: 40%;
        .infobox {
          box-sizing: border-box;
          width: 48.4%;
          margin: 0.8%;
          border: 1px solid #fff;
          box-shadow: 1px 1px #777;
          border-radius: 7px;
          position: relative;
        }
        // .infoboxbig {
        //   box-sizing: border-box;
        //   width: 98.4%;
        //   margin: 0.8%;
        //   border: 1px solid #fff;
        //   box-shadow: 1px 1px #777;
        //   border-radius: 7px;
        //   position: relative;
        // }
      }
    }
  }
}
.bg {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: -999;
}
.bg img {
  min-height: 100%;
  width: 100%;
}
.title {
  background: linear-gradient(to bottom, #499bea 0, #207ce5 100%);
  border-radius: 7px 7px 0 0;
  font-size: 1.2vw;
  color: #fff;
  line-height: 1.2;
  text-shadow: 1px 1px #000;
  font-weight: 700;
  padding: 3px;
}
.text {
  color: #333;
  font-size: 2.2vw;
  text-shadow: 2px 2px #fff;
  line-height: 55px;
  padding-top: 2px;
  .unit {
    font-size: 18px;
    position: absolute;
    height: 15px;
    right: 2px;
    bottom: 30px;
  }
}
.update {
  width: 100%;
  margin: 0.8%;
  border: 1px solid #fff;
  background: #7abcff;
  background: linear-gradient(to bottom, #7abcff 0, #60abf8 44%, #4096ee 100%);
  border-radius: 10px;
  text-align: left;
  font-size: 20px;
  color: #fff;
  text-shadow: 1px 1px #000;
  padding-left: 15px;
  box-shadow: 1px 1px #777;
  letter-spacing: 1px;
}
.bottom {
  margin: 0 calc(0.8% - 1px);
  margin-bottom: 0.8%;
  border: 2px solid #fff;
  border-radius: 10px;
  font-size: 28px;
  box-shadow: 0 1px #777;
  color: #3d90e9;
  text-shadow: 1px 1px #3d90e9;
  letter-spacing: 3px;
  background: linear-gradient(
    to right,
    rgba(194, 241, 12, 0.65) 0,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 0) 60%,
    rgba(194, 241, 12, 0.65) 100%
  );
  white-space: nowrap;
  text-overflow: clip;
  overflow: hidden;
  .bottomText {
    width: auto;
    letter-spacing: 3px;
    white-space: nowrap;
    animation: marque-animation 30s infinite linear;

    .text_translate {
      font-size: 24px;
      letter-spacing: 0.9px;
    }
  }
}
h1 {
  margin: 0;
}
p {
  margin: 0;
}
</style>
